<script>
import TablePage from '../../../../../../components/table_page';
import Form from '../form';
// import request from '../../../../../../utils/request';

export default {
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {
      formConfig: {},
      selection: [],
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  created() {
    this.getConfigList('dms_rebate_config_table');
  },
  methods: {
    modalClick({ val, row }) {
      if (val.code === 'edit') {
        this.modalConfig.title = '编辑';
        this.formConfig.selection = [row.id];
        this.openModal();
      } else if (val.code === 'add') {
        this.modalConfig.title = '新增';
        this.formConfig.selection = [];
        this.openModal();
      } else if (val.code === 'view') {
        this.modalConfig.title = '查看';
        this.formConfig.selection = [row.id];
        this.openModal();
      }
      return true;
    },
  },
};
</script>
