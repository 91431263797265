<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';

export default {
  extends: Form,
  data() {
    return {
      rule: [],
    };
  },
  components: {
    formCreate: formCreate.$form(),
  },
  async mounted() {
    this.rule = await this.getBeforFormRule('dms_rebate_config_form');
    this.rule.map((v) => {
      const rowData = v;
      if (rowData.field === 'btnName') {
        rowData.props = {
          ...rowData.props,
          name: '查询',
        };
        rowData.on = {
          click: this.searchBean,
        };
      }
      if (rowData.field === 'beanName') {
        rowData.on = {
          ...rowData.on,
          change: () => this.setValue({ beanPath: '' }),
        };
      }
      if (rowData.field === 'beanPath') {
        rowData.props = {
          ...rowData.props,
          disabled: true,
        };
      }
      return rowData;
    });
    this.reload(this.rule);
    if (this.formConfig.selection[0]) {
      this.getData();
    }
  },
  methods: {
    searchBean(val) {
      const beanName = this.getFieldValue('beanName');
      if (beanName) {
        request
          .get(`/rebate/m/rebateVariable/validateBean/${beanName}`)
          .then((res) => {
            if (res.success) {
              this.setValue({
                beanPath: res.result.beanPath,
              });
            }
          });
      }
    },
    // 获取表单数据
    getData() {
      request
        .get(`/rebate/m/rebateVariable/findById/${this.formConfig.selection[0]}`)
        .then((res) => {
          if (res.success) {
            this.setValue({
              ...res.result,
              showFlag: res.result.showFlag === 1,
            });
          }
        });
    },
    // 提交
    async submit() {
      const params = {
        ...this.getFormData(),
        showFlag: this.getFieldValue('showFlag') ? 1 : 0,
      };
      delete params.btnName;
      let path = '';
      if (this.formConfig.selection[0]) {
        path = '/rebate/m/rebateVariable/update';
      } else {
        path = '/rebate/m/rebateVariable/add';
        delete params.id;
      }
      // return false;
      request
        .post(path, params)
        .then((res) => {
          if (res.success) {
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      return true;
    },
  },
};
</script>
